@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-Black.ttf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-ExtraLightItalic.ttf');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-Light.ttf');
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-LightItalic.ttf');
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-Regular.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-ThinItalic.ttf');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-Medium.ttf');
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-MediumItalic.ttf');
  font-style: italic;
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-SemiBold.ttf');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-Bold.ttf');
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-BoldItalic.ttf');
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-ExtraBold.ttf');
  font-style: normal;
  font-weight: 800;
}

@font-face {
  font-family: Poppins;
  src: url('./Assets/fonts/Poppins-ExtraBoldItalic.ttf');
  font-style: italic;
  font-weight: 800;
}
body {
  margin: 0;
  font-family:
    Poppins,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
